exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-uslugi-akcesoria-budowlane-oraz-szalunkowe-js": () => import("./../../../src/pages/uslugi/akcesoria-budowlane-oraz-szalunkowe.js" /* webpackChunkName: "component---src-pages-uslugi-akcesoria-budowlane-oraz-szalunkowe-js" */),
  "component---src-pages-uslugi-deskowanie-z-montazem-js": () => import("./../../../src/pages/uslugi/deskowanie-z-montazem.js" /* webpackChunkName: "component---src-pages-uslugi-deskowanie-z-montazem-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-uslugi-ogrodzenia-js": () => import("./../../../src/pages/uslugi/ogrodzenia.js" /* webpackChunkName: "component---src-pages-uslugi-ogrodzenia-js" */),
  "component---src-pages-uslugi-serwis-szalunkow-js": () => import("./../../../src/pages/uslugi/serwis-szalunkow.js" /* webpackChunkName: "component---src-pages-uslugi-serwis-szalunkow-js" */),
  "component---src-pages-uslugi-sklejka-js": () => import("./../../../src/pages/uslugi/sklejka.js" /* webpackChunkName: "component---src-pages-uslugi-sklejka-js" */),
  "component---src-pages-uslugi-sprzedaz-i-dierzawa-szalunkow-js": () => import("./../../../src/pages/uslugi/sprzedaz-i-dierzawa-szalunkow.js" /* webpackChunkName: "component---src-pages-uslugi-sprzedaz-i-dierzawa-szalunkow-js" */)
}

